import { isDevelopment } from "hooks/useIsDevelopment";
import { IMAGE_IDENTIFIERS } from "../googleDocs.consts";

export const getImageUrl = (imageUrl: string, imageIdentifier: string) => {
  if (imageUrl.indexOf("google") !== -1) return imageUrl;
  const isDev = isDevelopment();
  const rootURL = isDev
    ? process.env.REACT_APP_TEST_FRONTEND
    : process.env.REACT_APP_FRONTEND_URL;
  switch (imageIdentifier) {
    case IMAGE_IDENTIFIERS.HEADER_IMAGE:
      return `${rootURL}/assets/report-templates/report-headers/${imageUrl}`;
    case IMAGE_IDENTIFIERS.DOCTOR_URL:
      return `${rootURL}/assets/report-templates/radiologist-signature/${imageUrl}`;
    default:
      console.warn("Nothing configured");
      return null;
  }
};
