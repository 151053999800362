import { useEffect } from "react";
import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import { REQUIRES_PARSED_METADATA } from "../consts/tools.consts";
import { useImageMetaData } from "pages/viewer/hooks/metadata/useImageMetaData";

export const useParseImageMetadataOnLoad = () => {
  const { imageMetaData } = useImageMetaData();
  useEffect(() => {
    if (imageMetaData) {
      const enabledElementsArray = cornerstone.getEnabledElements();
      enabledElementsArray.forEach((enabledElement) => {
        REQUIRES_PARSED_METADATA.forEach((id) => {
          try {
            const toolInstance = cornerstoneTools.getToolForElement(
              enabledElement.element,
              id
            );
            if (toolInstance && toolInstance.setImageMetadata) {
              toolInstance.setImageMetadata(imageMetaData);
            }
          } catch (error) {
            console.error(error);
            console.warn(`error trying to parse metadata for ${id}`);
          }
        });
      });
    }
  }, [imageMetaData]);
};
