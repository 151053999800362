// @ts-nocheck
import React, { Component } from "react";
import cornerstoneTools from "cornerstone-tools";
import cornerstoneMath from "cornerstone-math";
import cornerstone, { CanvasCoordinate, enable } from "cornerstone-core";
import Drawing from "../../api/Drawing";
import Util from "../../api/Util";
import Manipulators from "../../api/Manipulators";
import {
  TOOL_IDS,
  XCALIBER_ANALYSIS_MODELS,
  XCALIBER_SLIDER_RESULT_TYPES,
  XCALIBER_NON_SLIDER_RESULT_TYPES,
} from "../../consts/tools.consts";
import { getDimensionData } from "../../measurement-tools/measurementToolUtils";
import { formatHandles } from "./formatHandles";
import { XcaliberResult } from "./xcaliber.types";
import { XcaliberSubscriptionLink } from "./XcaliberSubscriptionLink";
import { XcaliberParameters } from "pages/viewer/dicomViewer.types";
import { retrieveXcaliberResult } from "services/xCaliber";
import { toast } from "react-toastify";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);
const circleRoiCursor = cornerstoneTools.importInternal("tools/cursors");
const roundToDecimal = cornerstoneTools.importInternal("util/roundToDecimal");
const drawLinkedTextBox = cornerstoneTools.import("drawing/drawLinkedTextBox");
const drawRect = cornerstoneTools.import("drawing/drawRect");
const drawLine = cornerstoneTools.importInternal("drawing/drawLine");
const drawPolyLine = cornerstoneTools.import("drawing/drawPolyline");
const drawTextBox = cornerstoneTools.import("drawing/drawTextBox");

export class XcaliberTool extends BaseAnnotationTool {
  xcaliberAnalysis: XcaliberResult;

  contextFunction:
    | null
    | ((toolId: string, data: { x: number; y: number }) => void);

  constructor(props = {}) {
    const defaultProps = {
      name: TOOL_IDS.XCALIBER_TOOL,
      supportedInteractionTypes: ["Mouse", "Touch"],
      svgCursor: circleRoiCursor,
      configuration: {
        handleRadius: 2,
        renderDashed: false,
        hideHandlesIfMoving: true,
        prePlacementHandleRadius: 2,
        drawHandlesOnHover: true,
        circleColour: "aqua",
        circleColourOutsideImage: "red",
        labelColour: "aqua",
        measurementColour: "white",
        lineColour: "lime",
        lineColourOutsideImage: "red",
      },
    };
    super(props, defaultProps);
    this.hasIncomplete = false;
    this.preventNewMeasurement = false;
    this.currentPoint = null;

    //NOT REQUIRED
    this.imageMetadata = {};
    this.setImageMetadata = this.setImageMetadata.bind(this);

    this.xcaliberParemeters = {};
    this.setXcaliberAnalysis = this.setXcaliberAnalysis.bind(this);

    this.toolData = null;
    this.xcaliberAnalysis = null;
    this.handles = {
      start: { x: null, y: null, highlight: false, active: false },
      end: { x: null, y: null, highlight: false, active: false },
    };
    this.currentThreshold = 30;
    this.element = null;
    this.initialDataPlaced = false;
    this.selectedModels = [];
  }

  //NOT REQUIRED
  public setImageMetadata(imageMetaData: ImageMetadata) {
    if (imageMetaData) {
      this.imageMetadata = imageMetaData;
    }
  }

  public setXcaliberAnalysis(xcaliberAnalysis: XcaliberResult) {
    if (xcaliberAnalysis) {
      this.xcaliberAnalysis = xcaliberAnalysis;
      //console.log("Xcaliber Analysis Stored in Tool: ", this.xcaliberAnalysis);
    }
  }

  public setXcaliberThreshold(threshold) {
    this.currentThreshold = threshold;
  }

  public getModelTypes() {
    const modelsInAnalysis = XCALIBER_ANALYSIS_MODELS.filter(
      (model) => model in this.xcaliberAnalysis.data
    );
    return modelsInAnalysis;
  }

  public setXcaliberModelSelections(modelsToShow) {
    this.selectedModels = modelsToShow;
  }

  public getMeasurementData(element) {
    if (this.xcaliberAnalysis === null) {
      console.log("No xcaliber analysis is set for the tool");
      return {};
    }
    if (this.xcaliberAnalysis.errorMessage) {
      toast.error(this.xcaliberAnalysis.errorMessage);
    }
    const xcaliberData = this.xcaliberAnalysis.data;
    if (xcaliberData === undefined || xcaliberData == {}) {
      console.log("No xcaliber analysis data exists in tool");
      return {};
    }
    if (xcaliberData.species === "UNKNOWN") {
      toast.error("Species indeterminable from DICOM. Assuming Canine.");
    }

    const thresholdSlider = document.getElementById("xcaliberSlider");
    this.currentThreshold = thresholdSlider.value;

    const labelIndexScale = 20; //Math.round(fontSize*1.5);//20;//120;
    const handles = {};
    const includedAnalysesModels = XCALIBER_ANALYSIS_MODELS.filter(
      (model) => model in xcaliberData
    ).map((model) => ({ model, data: xcaliberData[model].analysisRslt }));
    //console.log("Included Analyses Models: ", includedAnalysesModels);
    includedAnalysesModels.forEach((includedModel, includedModelIndex) => {
      //console.log('Included model: ', includedModel);
      if (includedModel.data === undefined) {
        return;
      }
      includedModel.data.forEach((modelData, modelDataIndex) => {
        //console.log('Analysis (modelData): ', modelData);
        let multilabelClassificationLabel = "";
        let scoredLabels = [];
        modelData.results.forEach((modelDataResults, dataIndex) => {
          //console.log("Analysied data (modelDataResults): ", modelDataResults);

          if (modelData.result_type == "object_detection") {
            if (modelDataResults.boxes.length === 0) {
              toast.error(`No data retrieved for ${modelData.model_title}`);
            }
            modelDataResults.boxes.forEach((box, boxIndex) => {
              handles[
                `start_${includedModelIndex}${modelDataIndex}${dataIndex}${boxIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: true,
                moveable: false,
                x: box[0],
                y: box[1],
                score: modelDataResults.scores[boxIndex] * 100,
                model: modelData.model_title,
                resultType: modelData.result_type,
              };
              handles[
                `end_${includedModelIndex}${modelDataIndex}${dataIndex}${boxIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: true,
                moveable: false,
                x: box[2],
                y: box[3],
                score: modelDataResults.scores[boxIndex] * 100,
                label: modelData.classes[modelDataResults.labels[boxIndex]],
                model: modelData.model_title,
                resultType: modelData.result_type,
              };
              handles[
                `textbox_${includedModelIndex}${modelDataIndex}${dataIndex}${boxIndex}`
              ] = {
                active: true,
                hasMoved: false,
                locked: false,
                movesIndependently: false,
                drawnIndependently: true,
                allowedOutsideImage: true,
                hasBoundingBox: true,
                placed: true,
                insideImage: true,
                score: modelDataResults.scores[boxIndex] * 100,
                label: modelData.classes[modelDataResults.labels[boxIndex]],
                model: modelData.model_title,
                resultType: modelData.result_type,
              };
            });
          } else if (modelData.result_type == "multilabel_classification") {
            if (modelDataResults.labels.length === 0) {
              toast.error(`No data retrieved for ${modelData.model_title}`);
            }

            //Create the results label string, sorted according to score
            const sortedModelDataResults = modelDataResults.labels
              .map((label, index) => index)
              .sort(
                (a, b) =>
                  modelDataResults.scores[b] - modelDataResults.scores[a]
              ); //new
            sortedModelDataResults.forEach((labelIndex) => {
              const labelText =
                modelData.classes[labelIndex] +
                " (" +
                _roundToOneDecimal(
                  modelDataResults.scores[labelIndex] * 100
                ) +
                "%)";
              if (modelDataResults.labels[labelIndex] === 1) {
                if (multilabelClassificationLabel === "") {
                  multilabelClassificationLabel = labelText;
                } else {
                  multilabelClassificationLabel += ", " + labelText;
                }
              };
              scoredLabels.push( {label: labelText, score: modelDataResults.scores[labelIndex]*100 } );
            });
            handles[
              `textbox_${includedModelIndex}${modelDataIndex}${dataIndex}`
            ] = {
              active: true,
              hasMoved: false,
              locked: false,
              movesIndependently: true,
              drawnIndependently: true,
              allowedOutsideImage: true,
              hasBoundingBox: true,
              placed: true,
              insideImage: true,
              score: 100,
              //actualScore: modelDataResults.labels[labelIndex]*100, //using 0*100 = 0% or 1*100 = 100% becuase scores are provided, but not used, for this model type
              label: multilabelClassificationLabel,
              model: modelData.model_title,
              resultType: modelData.result_type,
              scoredLabels: scoredLabels,
            };
          } else if (
            modelData.result_type === "instance_segmentation" ||
            modelData.result_type === "instance_segmentation_pointrand"
          ) {
            if (modelDataResults.labels.length === 0) {
              toast.error(`No data retrieved for ${modelData.model_title}`);
            }
            modelDataResults.labels.forEach((label, labelIndex) => {
              let polygonSmallestXx = 0; //extremity
              let polygonSmallestXy = 0; //corresponding coord
              let polygonLargestXx = 0; //extremity
              let polygonLargestXy = 0; //corresponding coord
              let polygonSmallestYx = 0; //corresponding coord
              let polygonSmallestYy = 0; //extremity
              let polygonLargestYx = 0; //corresponding coord
              let polygonLargestYy = 0; //extremity
              const reducedPolygons = _removeUnnecessaryPoints(
                modelDataResults.polygons
              );
              reducedPolygons[labelIndex].forEach(
                (polygonPoint, polygonPointIndex) => {
                  if (
                    polygonSmallestXx == 0 ||
                    polygonPoint[0] < polygonSmallestXx
                  ) {
                    polygonSmallestXx = polygonPoint[0]; //extremity
                    polygonSmallestXy = polygonPoint[1]; //corresponding coord
                  }
                  if (polygonPoint[0] > polygonLargestXx) {
                    polygonLargestXx = polygonPoint[0]; //extremity
                    polygonLargestXy = polygonPoint[1]; //corresponding coord
                  }
                  if (
                    polygonSmallestYy == 0 ||
                    polygonPoint[1] < polygonSmallestYy
                  ) {
                    polygonSmallestYx = polygonPoint[0]; //corresponding coord
                    polygonSmallestYy = polygonPoint[1]; //extremity
                  }
                  if (polygonPoint[1] > polygonLargestYy) {
                    polygonLargestYx = polygonPoint[0]; //corresponding coord
                    polygonLargestYy = polygonPoint[1]; //extremity
                  }
                  handles[
                    `point${polygonPointIndex}_${includedModelIndex}${modelDataIndex}${dataIndex}${labelIndex}`
                  ] = {
                    active: false,
                    highlight: true,
                    locked: false,
                    x: polygonPoint[0],
                    y: polygonPoint[1],
                    score: modelDataResults.scores[labelIndex] * 100,
                    model: modelData.model_title,
                    resultType: modelData.result_type,
                  };
                }
              );
              const polygonLabelPointX =
                polygonSmallestXx +
                Math.round((polygonLargestXx - polygonSmallestXx) / 2);
              const polygonLabelPointY = polygonLargestYy + labelIndexScale;
              handles[
                `textbox_${includedModelIndex}${modelDataIndex}${dataIndex}${labelIndex}`
              ] = {
                active: true,
                hasMoved: false,
                locked: false,
                movesIndependently: true,
                drawnIndependently: true,
                allowedOutsideImage: true,
                hasBoundingBox: true,
                placed: true,
                insideImage: true,
                score: modelDataResults.scores[labelIndex] * 100,
                label: modelData.classes[label],
                initialX: polygonLabelPointX,
                initialY: polygonLabelPointY,
                model: modelData.model_title,
                resultType: modelData.result_type,
                //anchors: [{x:polygonSmallestXx, y:polygonSmallestXy }, {x:polygonLargestXx, y:polygonLargestXy }, {x:polygonSmallestYx, y:polygonSmallestYy }, {x:polygonLargestYx, y:polygonLargestYy }]
                anchors: reducedPolygons[labelIndex].map(([x, y]) => ({
                  x,
                  y,
                })),
              };
            });
            if (modelDataResults.vhs_info) {
              handles[
                `longaxisstart_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.vhs_info.long_axis_xy[0],
                y: modelDataResults.vhs_info.long_axis_xy[1],
                axisscore: modelDataResults.vhs_info.VHS_Long_axis_score,
                model: modelData.model_title,
                score: 100, //modelDataResults.vhs_info.VHS_Score,
                resultType: modelData.result_type,
              };
              handles[
                `longaxisend_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.vhs_info.long_axis_xy[2],
                y: modelDataResults.vhs_info.long_axis_xy[3],
                axisscore: modelDataResults.vhs_info.VHS_Long_axis_score,
                model: modelData.model_title,
                score: 100, //modelDataResults.vhs_info.VHS_Score,
                resultType: modelData.result_type,
              };
              handles[
                `shortaxisstart_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.vhs_info.short_axis_xy[0],
                y: modelDataResults.vhs_info.short_axis_xy[1],
                axisscore: modelDataResults.vhs_info.VHS_Short_axis_score,
                model: modelData.model_title,
                score: 100, //modelDataResults.vhs_info.VHS_Score,
                resultType: modelData.result_type,
              };
              handles[
                `shortaxisend_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.vhs_info.short_axis_xy[2],
                y: modelDataResults.vhs_info.short_axis_xy[3],
                axisscore: modelDataResults.vhs_info.VHS_Short_axis_score,
                model: modelData.model_title,
                score: 100, //modelDataResults.vhs_info.VHS_Score,
                resultType: modelData.result_type,
              };
              handles[
                `t4longstart_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.vhs_info.t4_long[0],
                y: modelDataResults.vhs_info.t4_long[1],
                model: modelData.model_title,
                score: 100, //modelDataResults.vhs_info.VHS_Score,
                resultType: modelData.result_type,
              };
              handles[
                `t4longend_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.vhs_info.t4_long[2],
                y: modelDataResults.vhs_info.t4_long[3],
                model: modelData.model_title,
                score: 100, //modelDataResults.vhs_info.VHS_Score,
                resultType: modelData.result_type,
              };
              handles[
                `t4shortstart_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.vhs_info.t4_short[0],
                y: modelDataResults.vhs_info.t4_short[1],
                model: modelData.model_title,
                score: 100, //modelDataResults.vhs_info.VHS_Score,
                resultType: modelData.result_type,
              };
              handles[
                `t4shortend_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.vhs_info.t4_short[2],
                y: modelDataResults.vhs_info.t4_short[3],
                model: modelData.model_title,
                score: 100, //modelDataResults.vhs_info.VHS_Score,
                resultType: modelData.result_type,
              };
              const vhsLabel =
                "VHS Long Axis Score: " +
                modelDataResults.vhs_info.VHS_Long_axis_score +
                ",  VHS Short Axis Score: " +
                modelDataResults.vhs_info.VHS_Short_axis_score +
                ",  VHS Score: " +
                modelDataResults.vhs_info.VHS_Score;
              handles[
                `textbox_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: true,
                hasMoved: false,
                locked: false,
                movesIndependently: true,
                drawnIndependently: true,
                allowedOutsideImage: true,
                hasBoundingBox: true,
                placed: true,
                insideImage: true,
                score: 100,
                label: vhsLabel,
                model: modelData.model_title,
                resultType: modelData.result_type,
                initialX: modelDataResults.vhs_info.long_axis_xy[2],
                initialY:
                  modelDataResults.vhs_info.long_axis_xy[3] + labelIndexScale,
                //anchors: [ {x: modelDataResults.vhs_info.long_axis_xy[0], y: modelDataResults.vhs_info.long_axis_xy[1]}, {x: modelDataResults.vhs_info.long_axis_xy[2], y: modelDataResults.vhs_info.long_axis_xy[3]} ]
                anchors: [
                  {
                    x: modelDataResults.vhs_info.long_axis_xy[0],
                    y: modelDataResults.vhs_info.long_axis_xy[1],
                  },
                  {
                    x: modelDataResults.vhs_info.long_axis_xy[2],
                    y: modelDataResults.vhs_info.long_axis_xy[3],
                  },
                  {
                    x: modelDataResults.vhs_info.short_axis_xy[0],
                    y: modelDataResults.vhs_info.short_axis_xy[1],
                  },
                  {
                    x: modelDataResults.vhs_info.short_axis_xy[2],
                    y: modelDataResults.vhs_info.short_axis_xy[3],
                  },
                ],
              };
            }
            if (modelDataResults.ctr_info) {
              handles[
                `heartmaxstart_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.ctr_info.heart_max_xy[0],
                y: modelDataResults.ctr_info.heart_max_xy[1],
                model: modelData.model_title,
                score: 100,
                resultType: modelData.result_type,
              };
              handles[
                `heartmaxend_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.ctr_info.heart_max_xy[2],
                y: modelDataResults.ctr_info.heart_max_xy[3],
                model: modelData.model_title,
                score: 100,
                resultType: modelData.result_type,
              };
              handles[
                `thoracidcavitymaxstart_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.ctr_info.thoracid_cavity_max_xy[0],
                y: modelDataResults.ctr_info.thoracid_cavity_max_xy[1],
                model: modelData.model_title,
                score: 100,
                resultType: modelData.result_type,
              };
              handles[
                `thoracidcavitymaxend_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.ctr_info.thoracid_cavity_max_xy[2],
                y: modelDataResults.ctr_info.thoracid_cavity_max_xy[3],
                model: modelData.model_title,
                score: 100,
                resultType: modelData.result_type,
              };
              const ctrLabel =
                "Heart Area: " +
                modelDataResults.ctr_info.heart_area +
                ",  Heart Length: " +
                modelDataResults.ctr_info.heart_len;
              handles[
                `textbox_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: true,
                hasMoved: false,
                locked: false,
                movesIndependently: true,
                drawnIndependently: true,
                allowedOutsideImage: true,
                hasBoundingBox: true,
                placed: true,
                insideImage: true,
                score: 100,
                label: ctrLabel,
                model: modelData.model_title,
                resultType: modelData.result_type,
                initialX: modelDataResults.ctr_info.heart_max_xy[2],
                initialY:
                  modelDataResults.ctr_info.heart_max_xy[3] +
                  labelIndexScale * 2,
              };
            }
            if (modelDataResults.vlas_info) {
              handles[
                `cvcstart_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.vlas_info.cvc_xy[0],
                y: modelDataResults.vlas_info.cvc_xy[1],
                vlas: modelDataResults.vlas_info.vlas,
                model: modelData.model_title,
                score: 100,
                resultType: modelData.result_type,
              };
              handles[
                `cvcend_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.vlas_info.cvc_xy[2],
                y: modelDataResults.vlas_info.cvc_xy[3],
                vlas: modelDataResults.vlas_info.vlas,
                model: modelData.model_title,
                score: 100,
                resultType: modelData.result_type,
              };
              handles[
                `vlasstart_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.vlas_info.vlas_xy[0],
                y: modelDataResults.vlas_info.vlas_xy[1],
                vlas: modelDataResults.vlas_info.vlas,
                model: modelData.model_title,
                score: 100,
                resultType: modelData.result_type,
              };
              handles[
                `vlasend_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: false,
                highlight: true,
                locked: false,
                x: modelDataResults.vlas_info.vlas_xy[2],
                y: modelDataResults.vlas_info.vlas_xy[3],
                vlas: modelDataResults.vlas_info.vlas,
                model: modelData.model_title,
                score: 100,
                resultType: modelData.result_type,
              };
              handles[
                `textbox_${includedModelIndex}${modelDataIndex}${dataIndex}`
              ] = {
                active: true,
                hasMoved: false,
                locked: false,
                movesIndependently: true,
                drawnIndependently: true,
                allowedOutsideImage: true,
                hasBoundingBox: true,
                placed: true,
                insideImage: true,
                score: 100,
                label: "VLAS: " + modelDataResults.vlas_info.vlas,
                model: modelData.model_title,
                resultType: modelData.result_type,
                initialX: modelDataResults.vlas_info.vlas_xy[2],
                initialY:
                  modelDataResults.vlas_info.vlas_xy[3] - labelIndexScale * 10,
                anchors: [
                  {
                    x: modelDataResults.vlas_info.vlas_xy[0],
                    y: modelDataResults.vlas_info.vlas_xy[1],
                  },
                  {
                    x: modelDataResults.vlas_info.vlas_xy[2],
                    y: modelDataResults.vlas_info.vlas_xy[3],
                  },
                ],
              };
            }
          }
        });
      });
    });
    //console.log("Handles: ", handles);

    const measurementData = {
      visible: true,
      active: true,
      invalidated: true,
      handles: handles,
    };
    return measurementData;
  }

  public async onToolActivation(element) {
    //THIS IS CALLED ON TOOL BUTTON CLICK

    if (
      this.xcaliberAnalysis?.subscribed === false ||
      this.xcaliberAnalysis?.subscribed === null
    ) {
      toast.warn(XcaliberSubscriptionLink);
      return;
    }

    const measurementData = await this.getMeasurementData(element);
    const toolState = await cornerstoneTools.getToolState(element, this.name);
    if (!toolState || !toolState.data || toolState.data.length === 0) {
      if (
        measurementData === null ||
        measurementData === undefined ||
        Object.keys(measurementData).length === 0
      ) {
        //TODO - Need to fix the case when re-selecting a different image and it has lost its XCaliber data
        //console.log("Measurement Data: ", measurementData);
        toast.error("Please refresh page to re-load AI analysis");
        return;
      }
      await cornerstoneTools.addToolState(element, this.name, measurementData);
    }

    await cornerstone.updateImage(element);
    this.element = element;
    //const xcaliberTool = cornerstoneTools.getToolForElement( element, 'XcaliberTool' );
  }

  createNewMeasurement(eventData) {}

  //Check whether each handle is inside the image boundary and update handle insideImage parameter if not. Not needed for Xcaliber Tool
  activeCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }
  onMeasureModified(evt) {
    //Not needed for XCaliber Tool
  }

  updateCachedStats(image, element, data) {}

  addNewMeasurement(evt, interactionType) {
    //Not used
    evt.preventDefault(); //Prevents the default behaviour of the event, and thus we can change the way the event is handled.
    evt.stopPropagation(); //Prevents the event from "bubbling up" the DOM tree and triggering other event listeners.
  }

  pointNearTool(element, data, coords, interactionType) {
    const pixelCoords = cornerstone.canvasToPixel(element, coords);
    const toolData = cornerstoneTools.getToolState(element, this.name);
    if (
      !toolData ||
      !toolData.data ||
      !toolData.data[0] ||
      !toolData.data[0].handles
    ) {
      return false;
    }
    for (const [key, handle] of Object.entries(toolData.data[0].handles)) {
      if (handle.x && handle.y) {
        const distance = cornerstoneMath.point.distance(
          { x: pixelCoords.x, y: pixelCoords.y },
          { x: handle.x, y: handle.y }
        );
        if (distance < 10) {
          return true;
        }
      }
    }
    return false;
  }

  getToolGeometry(element, data) {
    //THIS ISN'T USED
  }

  renderToolData(evt) {
    const enabledElement = cornerstone.getEnabledElement(evt.currentTarget);
    const toolState = cornerstoneTools.getToolState(
      enabledElement.element,
      this.name
    );
    if (!toolState) {
      console.log("Tool State Not Found");
      return;
    }

    const color = cornerstoneTools.toolColors.getColorIfActive(toolState);
    const canvas = enabledElement.canvas;
    const xcaliberAnalysisData = this.xcaliberAnalysis?.data;

    const {
      handleRadius,
      prePlacementHandleRadius,
      circleColour,
      circleColourOutsideImage,
      labelColour,
      measurementColour,
      lineColour,
      lineColourOutsideImage,
    } = this.configuration;

    const newContext = Drawing.getNewContext(canvas);
    const lineOptions = { color: color };

    //Hide Slider Element if no tool data
    const sliderElement = document.getElementById("xcaliberSlider");
    const sliderLabelElement = document.getElementById("xcaliberSliderLabel");
    const modelButtonsElement = document.getElementById("xcaliberModelButtons");
    const pdfButtonElement = document.getElementById("xcaliberPdfButton");

    //console.log("ToolState: ", toolState);
    if (!toolState || !toolState.data[0]) {
      console.log("Exiting Render Function - no tool data");
      sliderElement.style.display = "none";
      sliderLabelElement.style.display = "none";
      modelButtonsElement.style.display = "none";
      pdfButtonElement.style.display = "none";
      return;
    }

    const handles = toolState.data[0].handles;
    //console.log("Handles: ", handles);
    if (handles == null) {
      console.log("No handles exist in data. Exiting render function.");
      sliderElement.style.display = "none";
      sliderLabelElement.style.display = "none";
      modelButtonsElement.style.display = "none";
      return;
    }
    //console.log("HANDLES: ", handles);

    const containsSliderResultTypes = Object.values(handles).some(
      (handleObject) =>
        Object.values(handleObject).some((resultType) =>
          XCALIBER_SLIDER_RESULT_TYPES.includes(resultType)
        )
    );
    if (toolState && toolState.data && toolState.data.length > 0) {
      pdfButtonElement.style.display = "block";
      if (containsSliderResultTypes) {
        sliderElement.style.display = "block";
        sliderLabelElement.style.display = "block";
      } else {
        sliderElement.style.display = "none";
        sliderLabelElement.style.display = "none";
      }
      modelButtonsElement.style.display = "flex";
    } else {
      sliderElement.style.display = "none";
      sliderLabelElement.style.display = "none";
      modelButtonsElement.style.display = "none";
      pdfButtonElement.style.display = "none";
    }
    this.currentThreshold = sliderElement.value;

    //Group handles according to related analysis sections
    const handleGroups = {};
    for (const key of Object.keys(handles)) {
      const match = key.match(
        /^(start|end|textbox|shortaxisstart|shortaxisend|longaxisstart|longaxisend|t4shortstart|t4shortend|t4longstart|t4longend|heartmaxstart|heartmaxend|thoracidcavitymaxstart|thoracidcavitymaxend|cvcstart|cvcend|vlasstart|vlasend|point\d+)_(\d+)$/
      );
      if (match) {
        const prefix = match[1]; // 'start', 'end', 'textbox', etc
        const suffix = match[2]; // numerical suffix
        if (!handleGroups[suffix]) {
          handleGroups[suffix] = {
            start: null,
            end: null,
            textbox: null,
            score: null,
          };
        }
        handleGroups[suffix][prefix] = key;
        handleGroups[suffix]["score"] = handles[key].score;
        handleGroups[suffix]["model"] = handles[key].model;
        handleGroups[suffix]["resultType"] = handles[key].resultType;
      }
    }

    //Remove haneles having a score of 0
    const filteredHandleGroups = Object.fromEntries(
      Object.entries(handleGroups).filter(([key, value]) => value.score !== 0)
    );

    //Sort the groups according to score
    let sortedHandleGroupsArray = Object.entries(filteredHandleGroups);
    sortedHandleGroupsArray.sort(([, a], [, b]) => a.score - b.score);

    for (const [
      handleGroupIndex,
      handleGroupValue,
    ] of sortedHandleGroupsArray) {
      const { start, end, score, textbox, model, resultType } =
        handleGroupValue;
      const nonPointKeyCount = Object.keys(handleGroupValue).filter(
        (key) => !/^point/.test(key)
      ).length;

      //The model type field returned by XCaliber for some modely types differ from the actual requested model, so amending accordingly:
      let amendedModel = model;
      if (model === "vet_canine_thorax") {
        amendedModel = "vet_thorax";
      } else if (model === "vet_feline_ctr") {
        amendedModel = "CTR";
      } else if (model === "vlas") {
        amendedModel = "vet_canine_vlas";
      }

      //Draw the analyses
      if (
        score >= this.currentThreshold &&
        this.selectedModels.includes(amendedModel)
      ) {
        const scoreText =
          amendedModel === "vet_vhs" ||
          amendedModel === "CTR" ||
          amendedModel === "vet_canine_vlas" ||
          resultType === "multilabel_classification"
            ? ""
            : "(" + roundToDecimal(score, 2) + "%)";
        Drawing.draw(newContext, (context) => {
          if (resultType === "object_detection") {
            drawRect(
              context,
              enabledElement.element,
              handles[start],
              handles[end],
              lineOptions
            );

            if (!handles[textbox].hasMoved) {
              const coords = {
                x: Math.max(handles[start].x, handles[end].x),
                y: 0,
              };
              if (coords.x === handles[start].x) {
                coords.y = handles[start].y;
              } else {
                coords.y = handles[end].y;
              }
              handles[textbox].x = coords.x;
              handles[textbox].y = coords.y;
            }
            const textBoxAnchorPoints = (handles) =>
              _findTextBoxAnchorPoints(handles[start], handles[end]);
            drawLinkedTextBox(
              context, //The canvas context.
              enabledElement.element, //The element on which to draw the link. HTMLElement
              handles[textbox], //The textBox to link.
              `${handles[textbox].label} ${scoreText}`, //The text to display in the textBox.
              handles, //The handles of the annotation.
              textBoxAnchorPoints, //A function for an array of possible anchor points on the textBox.
              color, //The link color.
              1, //The line width of the link.
              1, //The x offset of the textBox.
              true //Vertically centers the text if true.
            );
          }
          if (resultType === "multilabel_classification") {
            const textBoxAnchorPoints = (handles) =>
              _findTextBoxAnchorPointsSingle(handles[textbox]);

            //const filteredLabels = handles[textbox].scoredLabels.filter( label => label.score >= this.currentThreshold);
            //const filteredLabelString = filteredLabels.map( label => label.label).join(", ");
            //const filteredLabels = handles[textbox].scoredLabels.filter( label => label.score >= this.currentThreshold);
            const filteredLabelString = handles[textbox].scoredLabels.filter( label => label.score >= this.currentThreshold).map( label => label.label).join(", ");

            if (!handles[textbox].hasMoved) {
              const coords = {
                x: 50,
                y:
                  xcaliberAnalysisData?.imageHeight !== undefined
                    ? xcaliberAnalysisData.imageHeight / 2
                    : 50,
              };
              handles[textbox].x = coords.x;
              handles[textbox].y = coords.y;
            }
            drawLinkedTextBox(
              context,
              enabledElement.element,
              handles[textbox],
              filteredLabelString,//`${handles[textbox].label} ${scoreText}`,
              handles,
              textBoxAnchorPoints,
              color,
              1,
              1,
              true
            );
          }
          if (
            resultType === "instance_segmentation" ||
            resultType === "instance_segmentation_pointrand"
          ) {
            if (textbox) {
              if (!handles[textbox].hasMoved) {
                const coords = {
                  x: handles[textbox].initialX,
                  y: handles[textbox].initialY,
                };
                handles[textbox].x = coords.x;
                handles[textbox].y = coords.y;
              }
              //const textBoxAnchorPoints = (handles) => _findTextBoxAnchorPoints(handles[start], handles[end]);
              const textBoxAnchorPoints = () => {
                return handles[textbox].anchors;
              };
              drawLinkedTextBox(
                context,
                enabledElement.element,
                handles[textbox],
                `${handles[textbox].label} ${scoreText}`,
                handles,
                textBoxAnchorPoints,
                color,
                1,
                1,
                true
              );
            }
            if (
              Object.keys(handleGroupValue).some(
                (key) =>
                  /^point\d+$/.test(key) && handleGroupValue[key] !== null
              )
            ) {
              //Removing non-point keys from length, and also factoring in the 'nextPointIndex'
              for (
                let pointIndex = 0;
                pointIndex <
                Object.keys(handleGroupValue).length - nonPointKeyCount - 1;
                pointIndex++
              ) {
                const nextPointIndex = pointIndex + 1;
                drawLine(
                  context,
                  enabledElement.element,
                  handles[handleGroupValue["point" + pointIndex]],
                  handles[handleGroupValue["point" + nextPointIndex]],
                  lineOptions
                );
              }
            }
            if (
              Object.keys(handleGroupValue).some((key) =>
                key.includes("short")
              ) &&
              Object.keys(handleGroupValue).some((key) => key.includes("long"))
            ) {
              drawLine(
                context,
                enabledElement.element,
                handles[handleGroupValue["shortaxisstart"]],
                handles[handleGroupValue["shortaxisend"]],
                lineOptions
              );
              drawLine(
                context,
                enabledElement.element,
                handles[handleGroupValue["longaxisstart"]],
                handles[handleGroupValue["longaxisend"]],
                lineOptions
              );
              drawLine(
                context,
                enabledElement.element,
                handles[handleGroupValue["t4shortstart"]],
                handles[handleGroupValue["t4shortend"]],
                lineOptions
              );
              drawLine(
                context,
                enabledElement.element,
                handles[handleGroupValue["t4longstart"]],
                handles[handleGroupValue["t4longend"]],
                lineOptions
              );

              if (!handles[textbox].hasMoved) {
                const coords = {
                  x: handles[textbox].initialX,
                  y: handles[textbox].initialY,
                };
                handles[textbox].x = coords.x;
                handles[textbox].y = coords.y;
              }
              const textBoxAnchorPoints = () => {
                return handles[textbox].anchors;
              };
              drawLinkedTextBox(
                context,
                enabledElement.element,
                handles[textbox],
                `${handles[textbox].label}`,
                handles,
                textBoxAnchorPoints,
                color,
                1,
                1,
                true
              );
            }
            if (
              Object.keys(handleGroupValue).some((key) => key.includes("max"))
            ) {
              drawLine(
                context,
                enabledElement.element,
                handles[handleGroupValue["heartmaxstart"]],
                handles[handleGroupValue["heartmaxend"]],
                lineOptions
              );
              drawLine(
                context,
                enabledElement.element,
                handles[handleGroupValue["thoracidcavitymaxstart"]],
                handles[handleGroupValue["thoracidcavitymaxend"]],
                lineOptions
              );

              if (!handles[textbox].hasMoved) {
                const coords = {
                  x: handles[textbox].initialX,
                  y: handles[textbox].initialY,
                };
                handles[textbox].x = coords.x;
                handles[textbox].y = coords.y;
              }
              const textBoxAnchorPoints = (handles) =>
                _findTextBoxAnchorPointsSingle(handles[textbox]);
              drawLinkedTextBox(
                context,
                enabledElement.element,
                handles[textbox],
                `${handles[textbox].label}`,
                handles,
                textBoxAnchorPoints,
                color,
                1,
                1,
                true
              );
            }

            if (
              Object.keys(handleGroupValue).some((key) =>
                key.includes("cvc")
              ) &&
              Object.keys(handleGroupValue).some((key) => key.includes("vlas"))
            ) {
              drawLine(
                context,
                enabledElement.element,
                handles[handleGroupValue["cvcstart"]],
                handles[handleGroupValue["cvcend"]],
                lineOptions
              );
              drawLine(
                context,
                enabledElement.element,
                handles[handleGroupValue["vlasstart"]],
                handles[handleGroupValue["vlasend"]],
                lineOptions
              );

              if (!handles[textbox].hasMoved) {
                const coords = {
                  x: handles[textbox].initialX,
                  y: handles[textbox].initialY,
                };
                handles[textbox].x = coords.x;
                handles[textbox].y = coords.y;
              }
              const textBoxAnchorPoints = () => {
                return handles[textbox].anchors;
              };
              drawLinkedTextBox(
                context,
                enabledElement.element,
                handles[textbox],
                `${handles[textbox].label}`,
                handles,
                textBoxAnchorPoints,
                color,
                1,
                1,
                true
              );
            }
          }
        });
      }
    }
  }
}

const updateToolData = () => {
  const enabledElements = cornerstone.getEnabledElements();
  if (enabledElements && enabledElements.length > 0) {
    enabledElements.forEach((enabledElement) => {
      const xcaliberTool = cornerstoneTools.getToolForElement(
        enabledElement.element,
        "XcaliberTool"
      );
      if (xcaliberTool) {
        cornerstone.updateImage(enabledElement.element);
      }
    });
  }
};

function _removeUnnecessaryPoints(points) {
  const getGradient = (point1, point2) => {
    const [x1, y1] = point1;
    const [x2, y2] = point2;
    return (y2 - y1) / (x2 - x1);
  };
  const processGroup = (group) => {
    if (group.length < 3) return group;
    const result = [group[0]];
    for (let i = 1; i < group.length - 1; i++) {
      const gradient1 = getGradient(group[i - 1], group[i]);
      const gradient2 = getGradient(group[i], group[i + 1]);
      if (gradient1 !== gradient2) {
        result.push(group[i]);
      }
    }
    result.push(group[group.length - 1]);
    return result;
  };
  return points.map(processGroup);
}

function _findTextBoxAnchorPoints(startHandle, endHandle) {
  return [
    {
      x: startHandle.x,
      y: startHandle.y,
    },
    {
      x: endHandle.x,
      y: endHandle.y,
    },
  ];
}

function _findTextBoxAnchorPointsSingle(handle) {
  return [
    {
      x: handle.x,
      y: handle.y,
    },
  ];
}

function _roundToOneDecimal(num) {
  if (Number.isInteger(num)) {
    return num;
  }
  return Math.round(num * 10) / 10;
}
