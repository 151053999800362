import { useCallback, useMemo } from "react";
import { Paper } from "@mui/material";
import { useOrderTools } from "./useOrderTools";
import { ToolToggleColumn } from "./ToolToggleColumn";
import { DicomToolbarSection } from "./DicomToolbarSection";
import { PersistentDrawer } from "components/drawer/Drawer";
import { DRAWER_SIDES } from "components/drawer/drawer.consts";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { VIEWPORT_STATUSES } from "pages/viewer/dicomViewer.consts";
import { useToolConfigForUser } from "pages/viewer/tools/hooks/useToolConfigForUser";

interface DicomViewerToolbarProps {
  setActiveTool: (selectedToolId: string | null) => void;
  isLoadingXcaliber: boolean;
}

export const DicomViewerToolbar = ({
  setActiveTool,
  isLoadingXcaliber,
}: DicomViewerToolbarProps) => {
  const tools = useToolConfigForUser();
  const selectableTools = useMemo(
    () => tools.filter((item) => !!item.iconFileName),
    [tools]
  );
  const toolColumns = useOrderTools(selectableTools);

  const handleToolSelect = useCallback(
    (selectedToolName: string) => {
      setActiveTool(selectedToolName);
    },
    [setActiveTool]
  );

  let backgroundThemeColor;

  backgroundThemeColor = "#f5f5f5";
  let toolBarThemeColor;
  toolBarThemeColor = "#f5f5f5";

  const DRAWER_STYLES = {
    widthPx: 180,
  };

  const {
    state: { viewportStatuses },
  } = useViewerContext();
  const isLoadingMetaData = useMemo(
    () =>
      viewportStatuses.some(
        (viewportStatus) =>
          viewportStatus === VIEWPORT_STATUSES.FETCHING_METADATA
      ),
    [viewportStatuses]
  );
  return (
    <PersistentDrawer
      drawerButtonTitle="TOOLS"
      drawerSide={DRAWER_SIDES.RIGHT}
      drawerStyles={DRAWER_STYLES}
    >
      <Paper
        style={{
          backgroundColor: backgroundThemeColor,
          overflow: "auto",
          padding: "10px 0",
        }}
        id="tools-container"
        elevation={3}
        sx={{ padding: 0.5 }}
      >
        {toolColumns.map(
          ({ sectionTitle, firstColumn, secondColumn, thirdColumn }) => (
            <DicomToolbarSection
              key={`section-${sectionTitle}`}
              sectionTitle={sectionTitle}
            >
              <ToolToggleColumn
                handleToolSelect={handleToolSelect}
                tools={firstColumn}
                isLoadingMetadata={isLoadingMetaData}
                isLoadingXcaliber={isLoadingXcaliber}
              />
              <ToolToggleColumn
                handleToolSelect={handleToolSelect}
                tools={secondColumn}
                isLoadingMetadata={isLoadingMetaData}
                isLoadingXcaliber={isLoadingXcaliber}
              />
              <ToolToggleColumn
                handleToolSelect={handleToolSelect}
                tools={thirdColumn}
                isLoadingMetadata={isLoadingMetaData}
                isLoadingXcaliber={isLoadingXcaliber}
              />
            </DicomToolbarSection>
          )
        )}
      </Paper>
    </PersistentDrawer>
  );
};
