import { useCallback, useMemo, useState } from "react";
import { REPORT_TYPES } from "../../studyTableRows.consts";
import { FIELD_KEYS } from "../../../study-table-columns/columns.consts";
import { useAppContext } from "context/app.context";
import { StudyData } from "../../../studyTable.types";
import { useUserType } from "hooks/useUserType";
import { USER_TYPES } from "consts";
import { updateStudyValues } from "services/studies/studies";
import { DefaultSpinner } from "components/loading/DefaultSpinner";
import { createReport } from "services/google-docs/createReport";

interface GoogleDocControllerProps {
  reportType: string;
  studyData: StudyData;
}
export const GoogleDocController = ({
  reportType,
  studyData,
}: GoogleDocControllerProps) => {
  const { iconUrl, isLoading, createReportOnClick, openReport, reportId } =
    useGoogleControllerProps(reportType, studyData);

  if (isLoading) {
    return <DefaultSpinner spinnerSize={25} />;
  }
  return (
    <img
      title={`${reportId ? "edit" : "add"} ${reportType} report`}
      src={iconUrl}
      height="30"
      style={{
        cursor: "pointer",
        filter: "drop-shadow(3px 3px 1px rgb(0 0 0 / 0.1)",
      }}
      onClick={reportId ? openReport : createReportOnClick}
    />
  );
};

const useGoogleControllerProps = (reportType, studyData: StudyData) => {
  const initialReportId = useMemo(() => {
    if (
      reportType === REPORT_TYPES.REFERRAL &&
      studyData[FIELD_KEYS.REFERRAL_REPORT_IDS]
    ) {
      return studyData[FIELD_KEYS.REFERRAL_REPORT_IDS];
    } else if (
      reportType === REPORT_TYPES.MEDICAL &&
      studyData[FIELD_KEYS.MEDICAL_REPORT_IDS]
    ) {
      return studyData[FIELD_KEYS.MEDICAL_REPORT_IDS];
    }
    return null;
  }, [studyData, reportType]);

  const [reportId, setReportId] = useState<string | null>(initialReportId);
  const iconUrl = useMemo(() => {
    const BASE_URL = "../assets/images/icons/table-icons/";
    const addOrEdit = reportId ? "" : "add-";

    switch (reportType) {
      case REPORT_TYPES.MEDICAL:
        return `${BASE_URL}${addOrEdit}medical-report-icon.svg`;
      case REPORT_TYPES.REFERRAL:
      default:
        return `${BASE_URL}${addOrEdit}referral-report-icon.svg`;
    }
  }, [reportType, reportId]);

  const {
    state: { userId },
  } = useAppContext();
  const clearDocumentId = useClearDocumentId(studyData.studypk, reportType);
  const openReport = useCallback(() => {
    clearDocumentId();
    window.open(
      `https://docs.google.com/document/d/${reportId}/edit`,
      "_blank"
    );
  }, [reportId, clearDocumentId]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const createReportOnClick = useCallback(async () => {
    setIsLoading(true);
    const reportId = await createReport(studyData, reportType, userId);
    setReportId(reportId);
    setIsLoading(false);
  }, [reportType, studyData, userId, setReportId, setIsLoading]);

  return { iconUrl, createReportOnClick, openReport, reportId, isLoading };
};

const useClearDocumentId = (studypk, reportType) => {
  // ONLY CLEARS REPORT IF DEVELOPER
  const {
    state: { userId },
  } = useAppContext();
  const { userType } = useUserType();
  return useCallback(async () => {
    if (userType === USER_TYPES.DEVELOPER && userId === "atxpublic") {
      const fieldKey =
        reportType === REPORT_TYPES.MEDICAL
          ? FIELD_KEYS.MEDICAL_REPORT_IDS
          : FIELD_KEYS.REFERRAL_REPORT_IDS;
      const result = await updateStudyValues(studypk, { [fieldKey]: null });

      return result;
    }
  }, [userType, studypk, reportType, userId]);
};
