import React from "react";
import App from "./App";
import "./css/global.min.css";
import initCornerstone from "./utils/cornerstone/initCornerstone";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

const root = createRoot(document.getElementById("root")); // Create a root.

initCornerstone();
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
