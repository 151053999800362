import { useState } from "react";
import { XcaliberSlider } from "pages/viewer/tools/miscellaneous-tools/xcaliber-tool/XcaliberSlider";
import { XcaliberModelButtons } from "pages/viewer/tools/miscellaneous-tools/xcaliber-tool/XcaliberModelButtons";
import { XcaliberPdfButton } from "pages/viewer/tools/miscellaneous-tools/xcaliber-tool/XcaliberPdfButton";

export const XcaliberControls = () => {
  const [selectedModels, setSelectedModels] = useState([]);

  return (
    <div
      id="xcaliberControls"
      style={{ display: "flex", alignItems: "center" }}
    >
        <XcaliberModelButtons selectedModels = {selectedModels} setSelectedModels={setSelectedModels} />
        <XcaliberSlider/>
        <XcaliberPdfButton selectedModels = {selectedModels} setSelectedModels={setSelectedModels} />
    </div>
  );
};
