import { postData } from "utils/networking/postData";

export const reportMessage = async (userId: string, message: string) => {
  const REPORT_MESSAGE_URL = "system/report-message";
  const result = await postData({
    uri: REPORT_MESSAGE_URL,
    body: {
      userId,
      message,
    },
  });
  return result as { success: boolean; message?: string };
};
