export const setUpHeaderAndFooter = async (
  documentId: string,
  accessToken: string
) => {
  const result = await fetch(
    "https://docs.googleapis.com/v1/documents/" + documentId + ":batchUpdate",
    {
      method: "POST",
      headers: new Headers({ Authorization: "Bearer " + accessToken }),
      body: JSON.stringify({
        requests: [
          {
            createHeader: {
              type: "DEFAULT",
              sectionBreakLocation: { index: 0 },
            },
          },
          { createFooter: { type: "DEFAULT" } },
        ],
      }),
    }
  );

  if (!result.ok) {
    const errorMessage = `Error creating new google document ${result.status}`;
    return { errorMessage };
  }
  const responseData = await result.json();
  const { headerId } = responseData.replies[0].createHeader;
  const { footerId } = responseData.replies[1].createFooter;
  return { headerId, footerId };
};
