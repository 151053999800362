import { useState, useEffect } from "react";
import { useUserType } from "../../../../../hooks/useUserType";
import { XcaliberModelButton } from "../../../../../components/buttons/Buttons";
import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import { XCALIBER_ANALYSIS_MODELS, TOOL_IDS } from "../../consts/tools.consts";
import { useXcaliberAnalysis } from "pages/viewer/hooks/xcaliber/useXcaliberAnalysis";
import { useViewerContext } from "pages/viewer/context/viewer.context";

export const XcaliberModelButtons = ( {selectedModels, setSelectedModels} ) => {
  const { userTypeColour } = useUserType();
  const [availableModels, setAvailableModels] = useState([]);
  const { state: { viewportStatuses } } = useViewerContext();
  const xcaliberAnalysis = useXcaliberAnalysis();

  useEffect(() => {
    if (
      xcaliberAnalysis.xcaliberAnalysis != null &&
      xcaliberAnalysis.xcaliberAnalysis?.data
    ) {
      const availableModels = XCALIBER_ANALYSIS_MODELS.filter(
        (key) => key in xcaliberAnalysis.xcaliberAnalysis.data
      );
      setAvailableModels(availableModels);
      if (selectedModels.length == 0) {
        setSelectedModels(availableModels);
      }
    }
  }, [xcaliberAnalysis]);

  const handleClick = (buttonKey) => {
    setSelectedModels((prevSelectedModels) => {
      if (prevSelectedModels.includes(buttonKey)) {
        return prevSelectedModels.filter((model) => model !== buttonKey);
      } else {
        return [...prevSelectedModels, buttonKey];
      }
    });
  };
  
  useEffect(() => {
    updateToolData(selectedModels);
  }, [selectedModels, viewportStatuses]);

  const updateToolData = (modelsToShow) => {
    const enabledElements = cornerstone.getEnabledElements();
    if (enabledElements && enabledElements.length > 0) {
      enabledElements.forEach((enabledElement) => {
        const xcaliberTool = cornerstoneTools.getToolForElement(
          enabledElement.element,
          TOOL_IDS.XCALIBER_TOOL
        );
        if (xcaliberTool) {
          xcaliberTool.setXcaliberModelSelections(modelsToShow);
          cornerstone.updateImage(enabledElement.element);
        }
      });
    }
  };

  return (
    <div
      id="xcaliberModelButtons"
      style={{ display: "none", alignItems: "center" }}
    >
      <label id="xcaliberModelsLabel" style={{ color: "white" }}>
        AI Analysis Types:&nbsp;
      </label>
      {XCALIBER_ANALYSIS_MODELS.map((value) => {
        return (
          <XcaliberModelButton
            id={value + "_button"}
            key={value}
            onClick={() => handleClick(value)} //currently clicks automatically on initialisation
            buttoncolor={
              selectedModels.includes(value) ? userTypeColour : "white"
            }
            textcolor={
              selectedModels.includes(value) ? "white" : userTypeColour
            }
            display={availableModels.includes(value) ? "inline-block" : "none"}
          >
            {value.substring(value.lastIndexOf("_") + 1).toUpperCase()}
          </XcaliberModelButton>
        );
      })}
    </div>
  );
};
