import { RadiologySite } from "models/study.types";
import { setUpReportParameters } from "../set-up-report-parameters/setUpReportParameters";
import { toast } from "react-toastify";
import { reportMessage } from "services/system";

export const setUpContentAndStyles = async (
  reportType: string,
  patientData,
  siteInfo: RadiologySite | null,
  headerId: string,
  footerId: string,
  documentId: string,
  accessToken: string,
  userId: string
) => {
  const reportParameters = await setUpReportParameters(
    reportType,
    patientData,
    siteInfo,
    headerId,
    footerId
  );
  //Populate and style the document
  //Refer to https://developers.google.com/docs/api/reference/rest/v1/documents/request
  const styleAndFormattingResult = await fetch(
    "https://docs.googleapis.com/v1/documents/" + documentId + ":batchUpdate",
    {
      method: "POST",
      headers: new Headers({ Authorization: "Bearer " + accessToken }),
      body: JSON.stringify(reportParameters),
    }
  );
  if (!styleAndFormattingResult.ok) {
    const errorMessage = `Error creating new document ${styleAndFormattingResult.status}`;
    toast.error(errorMessage);
    reportMessage(userId, errorMessage);
  }
  const result = await styleAndFormattingResult.json();
  return result;
};
