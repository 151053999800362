import { useMemo } from "react";

export const useIsDevelopmentMode = () =>
  useMemo(() => {
    const location = window.location;
    const isDev = location.hostname === "localhost" && location.port === "3000";
    return isDev;
  }, []);

export const isDevelopment = () =>
  window.location.hostname === "localhost" && window.location.port === "3000";
