export const LOGO_URI =
  "https://drive.google.com/uc?export=view&id=1CdU6Bp8BnBCa2UrQJTx-Y2MWTDP-Mgdw";
export const LOGO_HEIGHT = 63;
export const LOGO_WIDTH = 210;
export const ATX_ADDRESS = "\n\n4/16 Murphy Street, O'connor WA,6163";
export const ATX_LOGO_INDEX = 0;
export const ATX_PHONE = "\nPhone: (08) 93314733\n";
export const REPORT_BODY =
  "\n\nX_RAY CERVICAL SPINE\n\nX-RAY THORACIC SPINE\n\nX-RAY LUMBOSACRAL SPINE / PELVIS / BOTH HIP JOINTS\n\n\n\nKind Regards,\n";

export const DOCTOR_SIGNATURE_WIDTH = 210; //Original is 7.41cm and there's 1/72 inch to a PT, so 210PT//150;//300,
export const DOCTOR_SIGNATURE_HEIGH = 63; //Original is 2.22cm and there's 1/72 inch to a PT, so 63PT//150;//300,
export const HEADER_LINE_EXTRA_CHARS = 3;

export const IMAGE_IDENTIFIERS = {
  HEADER_IMAGE: "HEADER_IMAGE",
  DOCTOR_URL: "DOCTOR_URL",
};

export const REPORT_PERMISSION = {
  type: "anyone",
  role: "writer",
};
