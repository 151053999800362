import { RadiologySite } from "models/study.types";
import { getImageUrl } from "./functions/getImageUrl";
import { IMAGE_IDENTIFIERS } from "./googleDocs.consts";

export const getPatientDetails = (patientData) => {
  const { patientName, patientId, patientDob, studyDate, referringDoctor } =
    patientData;
  return `Patient: ${patientName.toUpperCase()} \nPatient ID: ${patientId} \nDOB: ${patientDob.replaceAll(
    "/",
    "."
  )} Date: ${studyDate.replaceAll(
    "/",
    "."
  )}\n\nREFERRING DOCTOR: ${referringDoctor}`;
};

export const getRadiologistSignatureUri = (siteInfo: RadiologySite | null) => {
  if (siteInfo && siteInfo.radSigUrl) {
    return getImageUrl(siteInfo.radSigUrl, IMAGE_IDENTIFIERS.DOCTOR_URL);
  }
  return null;
};

export const getReportingDoctor = (siteInfo: RadiologySite | null) =>
  `\n ${siteInfo?.radiologist ?? ""}`;
