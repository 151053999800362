import {
  ToggleButton as MuiToggleButton,
  Tooltip,
  styled,
} from "@mui/material";
import { ToolSpinner } from "./ToolSpinner";
import { TOOL_ICON_PATH } from "pages/viewer/tools/consts/tools.consts";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useMemo } from "react";

const ToggleButton = styled(MuiToggleButton)<{
  isactive: boolean;
}>(({ theme, isactive }) => ({
  backgroundColor: isactive
    ? theme.palette.action.active
    : theme.palette.background.paper,
  margin: "0.1rem",
}));

const ToolIcon = styled("img")<{
  isActive: boolean;
}>(({ theme }) => ({
  height: "20px",
  width: "20px",
}));

interface ToolButtonProps {
  name: string;
  label: string;
  iconFileName: string;
  showLoadingMetaData: boolean;
  handleToolSelect: (toolId: string) => void;
  showLoadingXcaliber: boolean;
}
export const ToolButton = ({
  name,
  label,
  iconFileName,
  showLoadingMetaData,
  handleToolSelect,
  showLoadingXcaliber,
}: ToolButtonProps) => {
  const tooltipMessage = showLoadingMetaData
    ? `${label} requires metadata to load before use`
    : showLoadingXcaliber
    ? `${label} requires analysis to load before use`
    : label;

  const {
    state: { activeTools },
  } = useViewerContext();
  const isToolActive = useMemo(
    () => activeTools.includes(name),
    [activeTools, name]
  );
  const iconUrl = `${TOOL_ICON_PATH}${iconFileName}.svg`;
  return (
    <ToggleButton
      isactive={isToolActive}
      key={name}
      value={name}
      onClick={() => handleToolSelect(name)}
    >
      <Tooltip title={tooltipMessage} placement={"right"}>
        <div>
          {showLoadingMetaData || showLoadingXcaliber ? (
            <ToolSpinner />
          ) : (
            <ToolIcon
              isActive={isToolActive}
              alt={iconFileName}
              src={iconUrl}
            />
          )}
        </div>
      </Tooltip>
    </ToggleButton>
  );
};
