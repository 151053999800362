import { GoogleDocPermission } from "../googleDocs.types";

export const setDocumentPermissions = async (
  documentId: string,
  accessToken: string,
  permissions: GoogleDocPermission
) => {
  const result = await fetch(
    "https://www.googleapis.com/drive/v3/files/" + documentId + "/permissions",
    {
      method: "POST",
      headers: new Headers({ Authorization: "Bearer " + accessToken }),
      body: JSON.stringify(permissions),
    }
  );
  return result;
};
