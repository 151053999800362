// @ts-nocheck
import { useState, useEffect } from "react";
import { useUserType } from "../../../../../hooks/useUserType";
import { XcaliberModelButton } from "../../../../../components/buttons/Buttons";
import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import { XCALIBER_ANALYSIS_MODELS, TOOL_IDS, XCALIBER_NON_SLIDER_RESULT_TYPES } from "../../consts/tools.consts";
import { useXcaliberAnalysis } from "pages/viewer/hooks/xcaliber/useXcaliberAnalysis";
import { toast } from "react-toastify"; 
import useSelectedXcaliberModels from "pages/viewer/hooks/xcaliber/useSelectedXcaliberModels";
import { retrieveXcaliberPdf, submitXCaliberRequest } from "services/xCaliber";
import { useAppContext } from "context/app.context";
import { ToolSpinner } from "pages/viewer/components/dicom-viewer-toolbar/tool-button/ToolSpinner";

export const XcaliberPdfButton = (  {selectedModels, setSelectedModels}  ) => {
    const { userTypeColour } = useUserType();
    const [availableModels, setAvailableModels] = useState([]);
    const xcaliberAnalysis = useXcaliberAnalysis();
    const { state: { userId, xcaliberStatus } } = useAppContext();
    const [isRetrievingPdf, setIsRetrievingPdf] = useState(false);

    useEffect(() => {
        if (
            xcaliberAnalysis.xcaliberAnalysis != null &&
            xcaliberAnalysis.xcaliberAnalysis?.data
        ) {
            const availableModels = XCALIBER_ANALYSIS_MODELS.filter(
            (key) => key in xcaliberAnalysis.xcaliberAnalysis.data
            );
            setAvailableModels(availableModels);
        }
    }, [xcaliberAnalysis]);

    const handleClick = async () => {

        if(isRetrievingPdf) {
            console.log("Processing PDF Retrieval Request");
            return;
        }
        if(!xcaliberAnalysis || !xcaliberAnalysis.xcaliberAnalysis || !xcaliberAnalysis.xcaliberAnalysis.data) {
            toast.error("Cannot retrieve Pdf. No analysis available.");
            return;
        }

        const data = xcaliberAnalysis.xcaliberAnalysis.data;
        const thresholdSliderElement = document.getElementById("xcaliberSlider") as HTMLInputElement;
        let currentThreshold = thresholdSliderElement.value;

        availableModels.forEach( (model) => {
            const resultType = data[model]?.['analysisRslt']?.[0]?.result_type;
            if( XCALIBER_NON_SLIDER_RESULT_TYPES.includes(resultType) ) {
                currentThreshold = 30;
            }
            if(selectedModels.includes(model)) {
                const reqId = xcaliberAnalysis.xcaliberAnalysis.data[model]['analysisId']
                if(!userId) {
                    toast.error("Unable to download analysis report. User ID unknown.");
                    return;
                }
                if(!reqId) {
                    toast.error("Unable to download analysis report. Request ID missing.");
                    return;
                }
                downloadPDF(userId, reqId, currentThreshold);
            }
        });
    };

    const downloadPDF = async (userId, requestId, threshold) => {
        setIsRetrievingPdf(true);
        const result = await retrieveXcaliberPdf(userId, requestId, threshold);
        if (result.status && result.status == 202) {
            toast.success("Analysis in Progress. Please try again soon.", {
                position: "top-center",
                style: { background: this.colourValue, color: "white" },
            });
        } else {
            const url = window.URL.createObjectURL(result);
            const newWindow = window.open(url, "_blank");
            if (
                !newWindow ||
                newWindow.closed ||
                typeof newWindow.closed === "undefined"
            ) {
                alert(
                "The PDF could not be opened. Please check your popup blocker settings then retry"
                );
            }
        }
        setIsRetrievingPdf(false);
    };

    return (
        <XcaliberModelButton
            id='xcaliberPdfButton'
            key='xcaliberPdfButton'
            onClick={() => handleClick()} //currently clicks automatically on initialisation
            buttoncolor={ userTypeColour }
            textcolor={ isRetrievingPdf? userTypeColour : "white" }
            display={ "none" }
        >
        {isRetrievingPdf? <ToolSpinner /> : 'PDF'}
        </XcaliberModelButton>
    );
};
