import { useEffect } from "react";
import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import { REQUIRES_XCALIBER_PARAMETERS } from "../consts/tools.consts";
import { MIN_CLIENT_WIDTH_PX } from "pages/viewer/dicomViewer.consts";
import { XcaliberAnalysis } from "pages/viewer/dicomViewer.types";
import { useImageMetaData } from "pages/viewer/hooks/metadata/useImageMetaData";
import { useViewerContext } from "pages/viewer/context/viewer.context";

const getObjectUIDFromImageId = (imageId) => {
  const urlParams = new URLSearchParams(imageId.split("?")[1]);
  return urlParams.get("objectUID");
};

export const useParseXcaliberAnalysis = (
  xcaliberAnalysis: XcaliberAnalysis | null
) => {

  const { imageMetaData } = useImageMetaData();
  
  useEffect( () => {
    const fetchAndParseXcaliberAnalysis = async () => {
      if (xcaliberAnalysis) {
        const enabledElementsArray = cornerstone.getEnabledElements();
        for(const enabledElement of enabledElementsArray) {
          if (enabledElement.element.clientWidth > MIN_CLIENT_WIDTH_PX) {
            await new Promise((resolve) => setTimeout(resolve, 0)); //executes after the current call stack is cleared, giving time for enabledElement.image to load
            const enabledElementImageIuid = enabledElement?.image
            ? getObjectUIDFromImageId(enabledElement.image.imageId)
            : null;
          if (
            enabledElementImageIuid &&
            enabledElementImageIuid === xcaliberAnalysis.sopIuid
          ) {
            REQUIRES_XCALIBER_PARAMETERS.forEach((toolType) => {
              try {
                const toolInstance = cornerstoneTools.getToolForElement(
                  enabledElement.element,
                  toolType
                );
                if (toolInstance && toolInstance.setXcaliberAnalysis) {
                  toolInstance.setXcaliberAnalysis(xcaliberAnalysis);
                }
              } catch (error) {
                console.error(error);
                console.warn(
                  `Error trying to parse xcaliber analysis for ${toolType}`
                );
              }
            });
          }
          }
        };
      }
    };
    fetchAndParseXcaliberAnalysis();
  }, [xcaliberAnalysis, imageMetaData] );
  
  /*
  useEffect(() => {
    if (xcaliberAnalysis) {
      const enabledElementsArray = cornerstone.getEnabledElements();
      enabledElementsArray.forEach((enabledElement) => {
        if (enabledElement.element.clientWidth > MIN_CLIENT_WIDTH_PX) {
          const enabledElementImageIuid = enabledElement?.image
            ? getObjectUIDFromImageId(enabledElement.image.imageId)
            : null;
          if (
            enabledElementImageIuid &&
            enabledElementImageIuid === xcaliberAnalysis.sopIuid
          ) {
            REQUIRES_XCALIBER_PARAMETERS.forEach((toolType) => {
              try {
                const toolInstance = cornerstoneTools.getToolForElement(
                  enabledElement.element,
                  toolType
                );
                if (toolInstance && toolInstance.setXcaliberAnalysis) {
                  toolInstance.setXcaliberAnalysis(xcaliberAnalysis);
                }
              } catch (error) {
                console.error(error);
                console.warn(
                  `Error trying to parse xcaliber analysis for ${toolType}`
                );
              }
            });
          }
        }
      });
    }
  }, [xcaliberAnalysis, imageMetaData]);
  */
  
};
